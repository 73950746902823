import { useEffect, useState } from "react";
import { client } from "../../utils/client";

const useAxiosInterceptor = () => {
  const [loading, setLoading] = useState(0);
  const [configReady, setConfigReady] = useState(false);

  useEffect(() => {
    const myRequestInterceptor = client.interceptors.request.use((config) => {
      //exclude loading for check connectors polling & get calls polling
      if(!config?.url?.includes('/status-new-o2-connector') && !config?.url?.includes('/getcalls')){
        setLoading((counter) => counter + 1);
      }
        
      
      const token = localStorage.getItem("activation_token")
        ? localStorage.getItem("activation_token")
        : null;

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token,
          },
        };
      }

      return config;
    });

    const myResponseInterceptor = client.interceptors.response.use(
      (config) => {
        //exclude loading for check connectors polling & get calls polling
        if(!config?.request?.responseURL?.includes('/status-new-o2-connector') && !config?.request?.responseURL?.includes('/callbix/getcalls')){
          setLoading((counter) => counter - 1);
        }
          
        return config;
      },
      (error) => {
        setLoading((counter) => counter - 1);
        // Quando il server ritorna un errore possiamo mostrare un alert
      }
    );

    setConfigReady(true);

    return () => {
      client.interceptors.request.eject(myRequestInterceptor);
      client.interceptors.response.eject(myResponseInterceptor);
    };
  }, []);

  return { loading, configReady };
};

export default useAxiosInterceptor;
