import {
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import {
  Button,
  Dialog,
  Table,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import "../index.css";
import "./Dashboard.css";
import { client } from "../utils/client";
import { Formik } from "formik";
import ConnectorsForm from "./organisms/ConnectorsForm";
import { openAlert } from "../reducers/uiSlice";
import { useDispatch } from "react-redux";
import HeaderTab from "./atoms/HeaderTab";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import { useData } from "./sample/lib/useData";
import { TeamsUserCredential,TeamsUserCredentialAuthConfig  } from "@microsoft/teamsfx";

let platforms: any[] = [];

const Dashboard = () => {
  const { isInTeams } = useTeamsFx();
  const [existMicrosoftTeamsConnector, setMsConnector] = useState(true);

  const dispatch = useDispatch();

  let token: any;
  const [existConnectors, setExistConnectors] = useState(false);
  token = localStorage.getItem("activation_token")
    ? localStorage.getItem("activation_token")
    : null;
  const [visible, setVisible] = useState(false);
  let [platforms_to_show, setPlatforms] = useState<any>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<any>();
  const [connectors, setConnectors] = useState<any>([]);
  let platformMicrosoftTeams: any = null;
  const userOptions = localStorage.getItem("user_options") != null ? JSON.parse(localStorage.getItem("user_options")!) : null;

  var riconoscimentoChiamanteEnabled = false; 
  if(userOptions && userOptions.data.riconoscimentoChiamante.enabled)
       riconoscimentoChiamanteEnabled = true;

  const authConfig: TeamsUserCredentialAuthConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
  };
  const credential = new TeamsUserCredential(authConfig);
  const userProfile = useData(async () => {
    const credentialUser = isInTeams
      ? await credential.getUserInfo()
      : undefined;
    return credentialUser;
  })?.data;
  
  const userName = userProfile ? userProfile.displayName : "";
  if (localStorage.getItem("userName")) {
    localStorage.removeItem("userName");
    localStorage.setItem("userName", userName);
  } else {
    localStorage.setItem("userName", userName);
  }

  const header = {
    key: "header",
    items: [
      {
        content: "Nome",
        key: "name",
        //class: "ui-table__cell aq gz ha hb hc cl gb gc gd ge cq cr cs ct gf gg hd gi dn gn go gp gq do dp dq dr ds dt du dv dw dx dy dz ea eb ec ed ee ef eg eh gr gs gt gu em en eo ep eq er es et eu ev ew ex ey ez fb fc fd fe ff fg gv gw gx gy cd he cf hf bf hg text-bold"
      },
      {
        content: "Data creazione",
        key: "date-created",
        //class: "ui-table__cell aq gz ha hb hc cl gb gc gd ge cq cr cs ct gf gg hd gi dn gn go gp gq do dp dq dr ds dt du dv dw dx dy dz ea eb ec ed ee ef eg eh gr gs gt gu em en eo ep eq er es et eu ev ew ex ey ez fb fc fd fe ff fg gv gw gx gy cd he cf hf bf hg text-bold"
      },
      {
        key: "more options",
        "aria-label": "options"
      },
    ],
  };

  const deleteConnector = (id: any, name: string) => {
    try {
      client.delete("api/teams/v1/connector/" + id).then((response) => {
        if (response.data.data === true) {
          setConnectors((connectors: any) =>
            connectors.filter((item: any) => item.items[0] !== name)
          );
          window.location.reload();
        }
      });
    } catch (e) {}
  };
  
  async function getTokenSSO() {
    try {
      // let login = await credential.login(
      //   "email profile Channel.ReadBasic.All Files.ReadWrite.AppFolder Files.ReadWrite.All Files.Read.All User.Read Team.ReadBasic.All Sites.Read.All openid offline_access"
      // ); --old version
      const scope = ["email", "profile", "Channel.ReadBasic.All", "Files.ReadWrite.AppFolder", "Files.ReadWrite.All", "Files.Read.All", "User.Read", "Team.ReadBasic.All", "Sites.Read.All", "openid", "offline_access","Calendars.ReadWrite","Calendars.ReadWrite.Shared","Bookings.ReadWrite.All","Bookings.Manage.All","BookingsAppointment.ReadWrite.All"];
      const response_token = isInTeams
        //? await credential.getToken("") --old version
        ? await credential.getToken(scope)
        : undefined;
      if (response_token && !existMicrosoftTeamsConnector) {
        if (localStorage.getItem("tokenUser")) {
          localStorage.removeItem("tokenUser");
          localStorage.setItem("tokenUser", response_token.token);
        } else {
          localStorage.setItem("tokenUser", response_token.token);
        }
      }
      return response_token;
    } catch (error) {
      console.log(error);
    }
  }

  function exchangeToken() {
    if (platforms.length > 0) {
      const existTeamsConnector = platforms.some(
        (item: { platforms_id: string }) => item.platforms_id === platformMicrosoftTeams._id
      );
      setMsConnector(existTeamsConnector);
    } else if (platforms.length === 0) {
      setMsConnector(false);
    }
  }

  async function createTeamsConnector(data: any) {
    let connectorMicrosoftTeams = {
      name: "Microsoft Teams for Callbix",
      api_url: "https://graph.microsoft.com",
      tenant_id: "d0ec715e-eb09-409f-a54f-57c525d10667",
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      platforms_id: platformMicrosoftTeams._id,
    };
    const responseConnectorTeams = await client.post(
      "api/teams/v1/connector",
      connectorMicrosoftTeams
    );
    return responseConnectorTeams;
  }

  useEffect(() => {
    getCollaboratioPlatform().then(() =>{
      if (!existMicrosoftTeamsConnector) {
        getTokenSSO().then(() => {
          try {
            //get refresh token
            var refreshToken = "";
            Object.keys(sessionStorage).forEach((key) => {
                var obj = JSON.parse(sessionStorage.getItem(key)!);
                if(obj && typeof obj === 'object' && obj !== null && obj.hasOwnProperty('credentialType')){
                  if(obj.credentialType == 'RefreshToken')
                    refreshToken = obj.secret;
                }
            });
  
            if(refreshToken){
              var data = {
                access_token: localStorage.getItem("tokenUser"),
                refresh_token: refreshToken
              };
              // createTeamsConnector(data).then(
              //   (responseConnectorTeams: any) => {
              //     if (responseConnectorTeams.data.access) {
              //       getAndSetConnectors();
              //     }
              //   }
              // );
              console.log("removed auto connector")
              getAndSetConnectors();
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
    });
    
  }, [existMicrosoftTeamsConnector]);


  useEffect(() => {
    client
      .get("/api/teams/v1/connector")
      .then((response: any) => {
        platforms = response.data.data.platforms;
        if (platforms.length > 0) {
          let connectorsTable: { key: number; items: any[] }[] = [];
          platforms.forEach((element, index) => {
            let plat = platforms_to_show.find(function (obj: any) {
              return obj["_id"] === element.platforms_id;
            });
            let img = process.env.PUBLIC_URL + "/generic_connector.svg";
            if (plat) {
              console.log(plat);
              img = plat.img_btn ? process.env.REACT_APP_API_URL + "/" + plat.img_btn : img;
            }
            connectorsTable.push({
              key: index + element.name,
              items: [
                <>
                  <div style={{ display: "flex", alignItems: "center", wordBreak: "break-all"}}>
                    <img
                      src={img}
                      className="connectorImage"
                      alt=""
                      // style={{
                      //   height: "35px",
                      //   width: "35px",
                      //   borderRadius: "3px",
                      //   marginRight: "5px",
                      // }}
                    ></img>
                    {element.name}
                  </div>
                </>,
                element.created_at,
                <Dialog
                  cancelButton="Annulla"
                  confirmButton="Conferma"
                  onConfirm={() => deleteConnector(element._id, element.name)}
                  content="Sei sicuro che vuoi cancellare quest'app?"
                  header="Conferma"
                  //trigger={<Button icon={<TrashCanIcon />} text iconOnly style={{display:(riconoscimentoChiamanteEnabled ? "block" : "none")}} />}
                  trigger={<Button icon={<TrashCanIcon />} text iconOnly />}
                  
                />,
              ],
            });
          });
          setConnectors(connectorsTable);
          getCollaboratioPlatform().then(() =>{
            exchangeToken();
          });
          setExistConnectors(true);
        } else {
          getCollaboratioPlatform().then(() =>{
            exchangeToken();
          });
          setExistConnectors(false);
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }, [platforms_to_show]);

  
  useEffect(() => {
    client
      .get("api/teams/v1/callbix/platforms")
      .then((response: any) => {
        setPlatforms(response.data.data);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }, []);

  async function getCollaboratioPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/collaboration");
      const msTeams = response.data.data.find((element: any) => {
        return element.name === "microsoft365callbix";
      });
      platformMicrosoftTeams = msTeams;

    } catch (e) {
      console.log(e);
    }
  }


  const initialValues = useMemo(() => {
    let fields: Record<string, string> = {
      name: "",
      api_url: "",
      platforms_id: selectedPlatform ? selectedPlatform._id : null,
    };

    if (selectedPlatform?.variables?.length) {
      selectedPlatform.variables.forEach((field: string) => {
        fields[field] = "";
      });
    }

    if (selectedPlatform?.variables_disabled?.length) {
      selectedPlatform.variables_disabled.forEach((field: string) => {
        fields[field] = "";
      });
    }

    return fields;
  }, [selectedPlatform]);


  function getAndSetConnectors() {
    client.get("/api/teams/v1/connector").then((response: any) => {
      platforms = response.data.data.platforms;

      if (platforms.length > 0) {
        let connectorsTable: { key: number; items: any[] }[] = [];
        platforms.forEach((element, index) => {
          connectorsTable.push({
            key: index + element.name,
            items: [
              element.name,
              element.created_at,
              <Dialog
                cancelButton="Annulla"
                confirmButton="Conferma"
                onConfirm={() => deleteConnector(element._id, element.name)}
                content="Sei sicuro che vuoi cancellare quest'app?"
                header="Conferma"
                //trigger={<Button icon={<TrashCanIcon style={{display:(riconoscimentoChiamanteEnabled ? "block" : "none")}} /> } text iconOnly />}
                trigger={<Button icon={<TrashCanIcon /> } text iconOnly />}
              />,
            ],
          });
        });

        setExistConnectors(true);
        setConnectors(connectorsTable);
      }
    });
  }


  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const response = await client.post("api/teams/v1/connector", values);
        if (response.data.access) {
          getAndSetConnectors();
        }
        dispatch(
          openAlert({
            content: "Salvataggio effettuato con successo",
            type: "success",
          })
        );
      } catch (e) {
        dispatch(
          openAlert({
            content: "Errore salvataggio",
            type: "danger",
            keepOpen: true,
          })
        );
      }
    },
    [dispatch]
  );

  return (
    <div className="ms-Grid" dir="ltr">
      <HeaderTab></HeaderTab>
      <div className="ms-Grid-row">
        <div
          className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12 marginTabElements">
          <div className="ms-Grid-col ms-md4 ms-lg4 ms-xs4">
            <h1>Le tue app</h1>
          </div>

          {
          //riconoscimentoChiamanteEnabled && (
            (
            <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <ConnectorsForm
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={setSelectedPlatform}
              platforms_to_show={platforms_to_show}
            />
          </Formik>
          )}
          
        </div>
      </div>
      {existConnectors && (
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12 marginTabElements connectorTable">
            <Table
              header={header}
              rows={connectors}
              aria-label="Nested navigation"
              // accessibility={gridNestedBehavior}
            />
          </div>
        </div>
      )}
      {!existConnectors && (
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12"
            style={{ textAlign: "center" }}
          >
            <embed
              src={process.env.PUBLIC_URL + "/" + "img_no_connectors.svg"}
            ></embed>
          </div>
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 colorBlue"
            style={{ textAlign: "center" }}
          >
            <h3 className="colorBlue">Non hai ancora collegato un'app</h3>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
