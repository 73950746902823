import { useState } from "react";
import Login from "./Login";
import Settings from "./Settings";

var showFunction = Boolean(process.env.REACT_APP_FUNC_NAME);

export default function CallListTab() {
  const [authentication, setAuthentication] = useState(
    localStorage.getItem("activation_token") ? true : false
  );

  if (!authentication) {
    return (
      <Login
        onLoginSuccess={() => {
          setAuthentication(true);
        }}
      />
    );
  }

  return <Settings />;
}