import React, { FunctionComponent, useState } from "react";

import { useFormikContext } from "formik";
import { Button, AddIcon, Dialog } from "@fluentui/react-northstar";
import Connectors from "../Connectors";
import { client } from "../../utils/client";
import { useDispatch } from "react-redux";
import { openAlert } from "../../reducers/uiSlice";

const ConnectorsForm: FunctionComponent<any> =  ({
  selectedPlatform,
  setSelectedPlatform,
  platforms_to_show
}) => {
  const { values, submitForm, setFieldValue } = useFormikContext();
  const [disabledSaveConnector, setDisabledSaveConnector] = useState(true);
  const dispatch = useDispatch();

  const onVerify = async () => {
    // client.post
    try {
      const disabledVars = selectedPlatform.variables_disabled;
      const { data } = await client.post(
        "api/teams/v1/verifyconnector",
        values
      );
      if (data.data.access === false) {
        throw new Error("error");
      } else {
        dispatch(
          openAlert({
            content: "Verifica effettuata con successo. Salva per proseguire",
            type: "success",
          })
        );
        setDisabledSaveConnector(false);
      }

      if (data.data.hasOwnProperty("data")) {
        disabledVars.forEach((plat: string) => {
          const value = data.data.data[plat];

          if (value) {
            setFieldValue(plat, value);
          }
        });
      } else {
        disabledVars.forEach((variable_name: string) => {
          if (data[variable_name]) {
            setFieldValue(variable_name, data[variable_name]);
          }
        });
      }
    } catch (e) {
      dispatch(
        openAlert({
          content: "Errore verifica",
          type: "danger",
        })
      );
      console.log(e);
    }
  };

  return (
    <div
      className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 ms-xs8"
      style={{ textAlign: "right", marginTop: "20px" }}
    >
      <Dialog
        id="dialogConnectors"
        onConfirm={() => submitForm()}
        onCancel={() => setSelectedPlatform(null)}
        cancelButton="Cancella"
        confirmButton={{
          content: "Salva",
          disabled: disabledSaveConnector,
          className: "btnSaveConnector"
        }}
        className="connectorDialog"
        // style={{ width: "645px", height: "500px" }}
        content={{
          content: (
            <>
              {" "}
              <Connectors
                selectedPlatform={selectedPlatform}
                setSelectedPlatform={setSelectedPlatform}
                platforms={platforms_to_show}
                onVerify={onVerify}
                setDisabledSaveConnector={setDisabledSaveConnector}
              />
            </>
          ),
          styles: {
            // keep only 1 scrollbar while zooming
            height: "100%",
            maxHeight: "250px",
            overflow: "auto",
          },
        }}
        trigger={
          <Button
            icon={<AddIcon circular bordered outline />}
            content="Connetti una nuova App"
            iconPosition="before"
            className="backgroundColorBlue colorWhite"
          />
        }
      />
    </div>
  );
};

export default ConnectorsForm;
