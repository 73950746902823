import { Loader } from "@fluentui/react-northstar";
import React, { FunctionComponent } from "react";

const LoadingModal: FunctionComponent<{ loading: boolean }> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        zIndex: 9999,
      }}
    >
      <Loader style={{ margin: 100 }} />
    </div>
  );
};

export default LoadingModal;
