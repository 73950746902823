import { TeamsFx, LogLevel, setLogLevel, setLogFunction, IdentityType } from "@microsoft/teamsfx";
import { useData } from "./useData";
import { useTeams } from "msteams-react-base-component";

var startLoginPageUrl = process.env.REACT_APP_START_LOGIN_PAGE_URL;
var functionEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;
var clientId = process.env.REACT_APP_CLIENT_ID;
var tenantId = process.env.REACT_APP_TENANT_ID;
var clientSecret = process.env.REACT_APP_CLIENT_SECRET;
var authorityHost = process.env.REACT_APP_AUTHORITY_HOST;

// TODO fix this when the SDK stops hiding global state!
let initialized = false;

export function useTeamsFx() {
  const [result] = useTeams({});
  const { error, loading } = useData(async () => {
    if (!initialized) {
      if (process.env.NODE_ENV === "development") {
        setLogLevel(LogLevel.Verbose);
        setLogFunction((leve: LogLevel, message: string) => { console.log(message); });
      }
      // loadConfiguration({
      //   authentication: {
      //     initiateLoginEndpoint: startLoginPageUrl,
      //     clientId: clientId,
      //   },
      //   resources: [
      //     {
      //       type: ResourceType.API,
      //       name: "default",
      //       properties: {
      //         endpoint: functionEndpoint,
      //       },
      //     },
      //   ],
      // }); --old version

      initialized = true;
    }
  });
  const isInTeams = true;
  return { error, loading, isInTeams, ...result };
}
