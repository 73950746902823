import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Flex,
  FlexItem,
  InfoIcon,
  Input,
  TeamsMonochromeIcon,
  Text,
} from "@fluentui/react-northstar";
import React, { useCallback, useEffect, useState } from "react";
import { client } from "../utils/client";
import HeaderTab from "./atoms/HeaderTab";
import querystring from "querystring";

import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import { Field, Formik, Form, useFormik } from "formik";
import workflowConfig from "../workflowConfig.json";
import { useDispatch } from "react-redux";
import { openAlert } from "../reducers/uiSlice";
import Login from "./Login";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { useTeamsFx } from "./sample/lib/useTeamsFx";

const msal = require("@azure/msal-node");

const Workflow = () => {
  const [authentication, setAuthentication] = useState(
    localStorage.getItem("activation_token") ? true : false
  );
  let [templateWf, setTemplateWf] = useState<any>([]);
  let [startAndOut, setStartAndOut] = useState<any>(false);
  let platformMicrosoftTeams;
  // const [platformMicrosoftTeams, setPlatformMSTeams] = useState<any>();
  let [connectors, setConnectors] = useState<any>([]);
  let [teamsPlat, setTeamsPlat] = useState<any>();

  let [teams, setTeams] = useState<any>([]);
  let [channels, setChannels] = useState<any>([]);
  let [platforms_start_id, setPlatformStartId] = useState<any>([]);
  let [platforms_to_id, setPlatformToId] = useState<any>([]);

  let initialValues: any = [];
  // let [initialValues, setInitialValues] = useState<any>([]);
  let [collaborationPlatforms, setCollaborationPlatforms] = useState<any>([]);
  let [OAuth2ProtocolSettings, setOAuth2ProtocolSettings] = useState<any>();
  let loadData: any = { ...workflowConfig };
  const { isInTeams } = useTeamsFx();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    try {
      if (authentication) {
        getWfTemplates();
        getCollaboratioPlatform();
      }
    } catch (e) {
      console.log(e);
    }
  }, [authentication]);
  function getWfTemplates() {
    client.get("api/teams/v1/workflow/type/teams").then((response: any) => {
      setTemplateWf(response.data.data);

      //read platform settings
      getConnectors();
    });
  }
  useEffect(() => {
    let platforms_to_id_to_show: any[] = [];
    let platforms_start_id_to_show: any[] = [];
    templateWf.forEach((workflow: any) => {
      let platforms_id_start = workflow.platforms_input_id;
      let platforms_id_to = workflow.platforms_out_id;
      platforms_id_start.forEach((plat_id: any) => {
        connectors.find((element: any) => {
          if (element.platforms_id === plat_id) {
            let conn_to_add = {
              key: element._id,
              label: element.name,
              header: element.name,
            };
            platforms_start_id_to_show[workflow._id] = [conn_to_add];
          }
        });
      });
      setPlatformStartId(platforms_start_id_to_show);
      platforms_id_to.forEach((plat_id: any) => {
        connectors.find((element: any) => {
          if (element.platforms_id === plat_id) {
            let conn_to_add = {
              key: element._id,
              label: element.name,
              header: element.name,
            };
            platforms_to_id_to_show[workflow._id] = [conn_to_add];
          }
        });
      });
      setPlatformToId(platforms_to_id_to_show);
    });
  }, [templateWf, connectors]);

  function getTeamsChannels() {
    if (teamsPlat) {
      const json = {
        connector_id: teamsPlat._id,
        module: ["teams"],
      };
      client.post("api/teams/v1/getteams", json).then((response: any) => {
        let teams = response?.data.data.teams;
        let teams_to_show: any = [];
        if (teams?.length > 0) {
          teams.forEach((element: any) => {
            teams_to_show.push({
              key: element.id,
              label: element.name,
              header: element.name,
            });
          });
          setTeams(teams_to_show);
        }
        templateWf.forEach((workflow: any) => {
          if (workflow.workflow) {
            //SONO IN PUT
            if (workflow.actions_templates[0].select_channels) {
              getChannels(
                teamsPlat._id,
                workflow.workflow[0].actions_checked[0].teams
              );
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    let teamsPlatTemp = connectors.find((element: any) => {
      return element.name === "Microsoft Teams";
    });
    // check if connector teams exists and set teamsPlat variable
    setTeamsPlat(teamsPlatTemp);

    // setTeamsPlat(teamsPlat);
  }, [connectors]);
  useEffect(() => {
    getTeamsChannels();
  }, [teamsPlat]);

  async function getConnectors() {
    try {
      const response = client.get("/api/teams/v1/connector");
      setConnectors((await response).data.data.platforms);
    } catch (e) {
      console.log(e);
    }
  }
  function onChangeHandler(event: any, data: any) {
    //Need to get the selected value from dropdown
    let selectedTeamId = data.value.key;
    getChannels(teamsPlat._id, selectedTeamId);
  }
  async function getChannels(teamsConnectorId: any, idTeam: any) {
    let channelsPush: any = [];

    const channelResponse = await client.get(
      "/api/teams/v1/channels/ms_teams/" +
        teamsConnectorId +
        "/channels/" +
        idTeam
    );
    let channels_get = channelResponse.data.data;
    let channels_to_show: any = [];
    if (channels_get.length > 0) {
      channels_get.forEach((element: any) => {
        channels_to_show.push({
          key: element.id,
          label: element.displayName,
          header: element.displayName,
        });
      });

      channelsPush[idTeam] = channels_to_show;
      setChannels((prevState: any) => ({
        ...prevState,
        ...channelsPush,
      }));

      // setChannels(newChannels);
      // channels[idTeam] = [];
      // channels[idTeam] = channels_to_show;
    }
  }

  function setFieldsWf(valuesForm: any, wfTemplate: any) {
    let actions_checked = wfTemplate.actions_templates[0];
    let objPlatSettings;
    if (wfTemplate.show_select_to) {
      objPlatSettings = {
        platforms_settings_id: valuesForm.platforms_settings_id,
      };
    } else {
      objPlatSettings = { platforms_settings_id: teamsPlat._id };
    }

    let new_action = { ...actions_checked, ...objPlatSettings };
    if (wfTemplate.actions_templates[0].select_channels) {
      new_action = { ...new_action, ...{ channel: valuesForm.channel } };
    }
    if (wfTemplate.actions_templates[0].select_teams) {
      new_action = { ...new_action, ...{ teams: valuesForm.teams } };
    }
    if (wfTemplate.actions_templates[0].action_card) {
      let action_card = wfTemplate.actions_templates[0].action_card[0];
      if (action_card) {
        if (action_card.show_actions_teams_channel_orders) {
          action_card = {
            ...action_card,
            ...{
              show_actions_teams_channel: valuesForm.show_actions_teams_channel,
            },
          };
        }
      }
      new_action.action_card = [action_card];
    }
    new_action.module_id = new_action.module_id.$oid;

    let jsonWf = {
      name: wfTemplate.key_name,
      description: wfTemplate.key_description,
      enabled: valuesForm.enabled,
      platform_start_id: valuesForm.platform_start_id,
      activation_id: "",
      wf_start_id: wfTemplate.wf_start_id,
      condictions: [],
      webhook_semplisio: true,
      actions_checked: [new_action],
      actions_not_checked: [],
      sequential: false,
      wf_template_id: wfTemplate._id,
    };

    return jsonWf;
  }
  async function putWorkflow(values: any, wf: any) {
    let jsonWf = wf.workflow[0];
    jsonWf.enabled = values.enabled;
    jsonWf.platform_start_id = values.platform_start_id;

    if (wf.actions_templates[0].select_channels) {
      jsonWf.actions_checked[0].channel = values.channel;
    }
    if (wf.actions_templates[0].select_teams) {
      jsonWf.actions_checked[0].teams = values.teams;
    }

    if (wf.actions_templates[0].action_card) {
      let action_card = jsonWf.actions_checked[0].action_card[0];
      if (action_card) {
        if (action_card.show_actions_teams_channel_orders) {
          action_card.show_actions_teams_channel =
            values.show_actions_teams_channel;
        }
      }
    }

    jsonWf.actions_checked[0].module_id = jsonWf.actions_checked[0].module_id
      .$oid
      ? jsonWf.actions_checked[0].module_id.$oid
      : jsonWf.actions_checked[0].module_id;
    jsonWf.actions_checked[0].platforms_settings_id = jsonWf.actions_checked[0]
      .platforms_settings_id.$oid
      ? jsonWf.actions_checked[0].platforms_settings_id.$oid
      : jsonWf.actions_checked[0].platforms_settings_id;
    try {
      const putResponse = await client.put(
        "api/teams/v1/workflow/" + wf.workflow[0]._id,
        jsonWf
      );
      if (putResponse.data.access === true) {
        getWfTemplates();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function saveWorkflow(values: any, wf: any) {
    if (wf.workflow) {
      //PUT WORKFLOW
      putWorkflow(values, wf);
    } else {
      //POST WORKFLOW
      let jsonWorkflow = setFieldsWf(values, wf);
      try {
        const response = await client.post(
          "api/teams/v1/workflow",
          jsonWorkflow
        );
        if (response.data.access === true) {
          getWfTemplates();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  function checkExistConnectorsInAndOut(wf: any) {
    let platIn = wf.platforms_input_id;
    let platOut = wf.platforms_out_id;
    let countIn = 0;
    let countOut = 0;
    let existPlatformsStartAndOut = false;
    let existTeamsConnector = false;
    if (teamsPlat) {
      existTeamsConnector = true;
    }
    connectors.forEach((element: any) => {
      if (platIn.includes(element.platforms_id)) {
        countIn++;
      }
    });
    connectors.forEach((element: any) => {
      if (platOut.includes(element.platforms_id)) {
        countOut++;
      }
    });
    if (countIn > 0 && countOut > 0) {
      if (wf.select_channels) {
        if (existTeamsConnector) {
          existPlatformsStartAndOut = true;
        } else {
          existPlatformsStartAndOut = false;
        }
      } else {
        existPlatformsStartAndOut = true;
      }
    } else {
      existPlatformsStartAndOut = false;
    }
    setStartAndOut(!existPlatformsStartAndOut);
    return !existPlatformsStartAndOut;
  }

  if (!authentication) {
    return (
      <Login
        onLoginSuccess={() => {
          setAuthentication(true);
        }}
      />
    );
  }
  function getCollaboratioPlatform() {
    try {
      client
        .get("api/teams/v1/platform/collaboration")
        .then((response: any) => {
          const msTeams = response.data.data.find((element: any) => {
            return element.name === "microsoft365";
          });
          platformMicrosoftTeams = msTeams;
          // setPlatformMSTeams(msTeams);
        });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <HeaderTab></HeaderTab>

      <Flex hAlign="center" style={{ marginTop: "40px" }}>
        {/* <Button
          icon={<TeamsMonochromeIcon />}
          content="Collega il tuo account Teams"
          iconPosition="before"
          style={{ backgroundColor: "#208ead", color: "white" }}
        /> */}
      </Flex>
      <Flex>
        {startAndOut && (
          <Alert
            icon={<InfoIcon />}
            content="Per poter abilitare i tuoi workflow, devi collegare il tuo ecommerce e il tuo Erp. Vai nel tab 'Apps' e crea il tuo collegamento!"
          />
        )}
      </Flex>
      <Flex hAlign="center" style={{ marginTop: "40px" }}>
        {templateWf.length > 0 &&
          templateWf.map((wf: any, index: number) => {
            return (
              <FlexItem styles={{ marginRight: "20px" }} key={wf._id}>
                {
                  <Formik
                    enableReinitialize
                    initialValues={
                      wf.workflow
                        ? {
                            wf_name: wf.key_name,
                            teams:
                              wf.actions_templates &&
                              wf.actions_templates[0] &&
                              wf.actions_templates[0].select_teams
                                ? wf.workflow[0].actions_checked[0].teams
                                : "",
                            channel:
                              wf.actions_templates &&
                              wf.actions_templates[0] &&
                              wf.actions_templates[0].select_channels
                                ? wf.workflow[0].actions_checked[0].channel
                                : "",
                            platform_start_id: wf.workflow[0].platform_start_id,
                            platform_to_id:
                              wf.workflow[0].actions_checked[0]
                                .platforms_settings_id &&
                              wf.workflow[0].actions_checked[0]
                                .platforms_settings_id.$oid
                                ? wf.workflow[0].actions_checked[0]
                                    .platforms_settings_id.$oid
                                : null,
                            enabled: wf.enabled,
                            show_actions_teams_channel: false,
                          }
                        : {
                            wf_name: wf.key_name,
                            teams: "",
                            show_actions_teams_channel: false,
                          }
                    }
                    onSubmit={(values) => {
                      saveWorkflow(values, wf);
                    }}
                  >
                    {({ values, setFieldValue, submitForm, initialValues }) => (
                      <Card
                        disabled={checkExistConnectorsInAndOut(wf)}
                        aria-roledescription="card with image and text"
                        styles={{ minHeight: "300px", marginRight: "15px" }}
                      >
                        <Card.Body fitted>
                          <Form>
                            <Flex column gap="gap.small">
                              <Flex.Item size="size.quarter">
                                <>
                                  {platforms_start_id[wf._id] && (
                                    <Dropdown
                                      items={platforms_start_id[wf._id]}
                                      disabled={checkExistConnectorsInAndOut(
                                        wf
                                      )}
                                      defaultValue={platforms_start_id[
                                        wf._id
                                      ].find((element: any) => {
                                        return (
                                          element.key ===
                                          initialValues.platform_start_id
                                        );
                                      })}
                                      onChange={(event, data: any) => {
                                        setFieldValue(
                                          "platform_start_id",
                                          data && data.value
                                            ? data.value.key
                                            : null
                                        );
                                        if (wf.workflow) {
                                          putWorkflow(values, wf);
                                        }
                                      }}
                                      placeholder="Seleziona la piattaforma di start"
                                    />
                                  )}

                                  {wf.show_select_to &&
                                    platforms_to_id[wf._id] && (
                                      <Dropdown
                                        items={platforms_to_id[wf._id]}
                                        disabled={checkExistConnectorsInAndOut(
                                          wf
                                        )}
                                        defaultValue={platforms_to_id[
                                          wf._id
                                        ].find((element: any) => {
                                          return (
                                            element.key ===
                                            initialValues.platform_to_id
                                          );
                                        })}
                                        onChange={(event, data: any) => {
                                          setFieldValue(
                                            "platforms_settings_id",
                                            data && data.value
                                              ? data.value.key
                                              : null
                                          );
                                          if (wf.workflow) {
                                            putWorkflow(values, wf);
                                          }
                                        }}
                                        placeholder="Seleziona la piattaforma di arrivo"
                                      />
                                    )}
                                  {teams.length > 0 &&
                                    wf.actions_templates &&
                                    wf.actions_templates[0] &&
                                    wf.actions_templates[0].select_teams && (
                                      <Dropdown
                                        disabled={checkExistConnectorsInAndOut(
                                          wf
                                        )}
                                        items={teams}
                                        defaultValue={teams.find(
                                          (element: any) => {
                                            return (
                                              element.key ===
                                              initialValues.teams
                                            );
                                          }
                                        )}
                                        onChange={(event, data: any) => {
                                          setFieldValue(
                                            "teams",
                                            data && data.value
                                              ? data.value.key
                                              : null
                                          );
                                          onChangeHandler(event, data);
                                        }}
                                        placeholder="Seleziona il team"
                                        noResultsMessage="Non troviamo nessun Team con questo nome"
                                        getA11ySelectionMessage={{
                                          onAdd: (item) =>
                                            `${item} has been selected.`,
                                        }}
                                      />
                                    )}
                                  {channels[values.teams] &&
                                    wf.actions_templates &&
                                    wf.actions_templates[0] &&
                                    wf.actions_templates[0].select_channels && (
                                      <Dropdown
                                        disabled={checkExistConnectorsInAndOut(
                                          wf
                                        )}
                                        items={channels[values.teams]}
                                        value={channels[values.teams].find(
                                          (element: any) => {
                                            return (
                                              element.key ===
                                              initialValues.channel
                                            );
                                          }
                                        )}
                                        onChange={(event, data: any) => {
                                          setFieldValue(
                                            "channel",
                                            data && data.value
                                              ? data.value.key
                                              : null
                                          );
                                          if (wf.workflow) {
                                            putWorkflow(values, wf);
                                          }
                                        }}
                                        placeholder="Seleziona il canale"
                                      />
                                    )}
                                </>
                              </Flex.Item>

                              <Text
                                weight="bold"
                                align="center"
                                content={
                                  loadData[wf.key_name]
                                    ? loadData[wf.key_name]
                                    : wf.key_name
                                }
                              />

                              {/* <button type="submit">test</button> */}
                              {wf.actions_templates &&
                                wf.actions_templates[0] &&
                                wf.actions_templates[0].action_card &&
                                wf.actions_templates[0].action_card[0]
                                  .show_actions_teams_channel_orders && (
                                  <Checkbox
                                    disabled={checkExistConnectorsInAndOut(wf)}
                                    onChange={(event, data) => {
                                      setFieldValue(
                                        "show_actions_teams_channel",
                                        data?.checked ? data.checked : false
                                      );
                                      if (wf.workflow) {
                                        putWorkflow(values, wf);
                                      }
                                    }}
                                    defaultChecked={
                                      initialValues.show_actions_teams_channel
                                        ? initialValues.show_actions_teams_channel
                                        : false
                                    }
                                    label="Autorizza automaticamente gli ordini"
                                  />
                                )}
                              <Checkbox
                                disabled={checkExistConnectorsInAndOut(wf)}
                                styles={{
                                  position: "absolute",
                                  bottom: "0",
                                  right: "0",
                                }}
                                defaultChecked={
                                  initialValues.enabled
                                    ? initialValues.enabled
                                    : false
                                }
                                onChange={(event, data) => {
                                  setFieldValue(
                                    "enabled",
                                    data?.checked ? data.checked : false
                                  );
                                  submitForm();
                                }}
                                toggle
                              />
                            </Flex>
                          </Form>
                        </Card.Body>
                      </Card>
                    )}
                  </Formik>
                }
              </FlexItem>
            );
          })}
      </Flex>
    </>
  );
};

export default Workflow;
