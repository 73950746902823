import { Button, Flex, Form, Dialog } from "@fluentui/react-northstar";
import "../index.css";
import { ChangeEvent, FunctionComponent,useEffect, useState } from "react";
import SelectIcons from "./SelectIcons";
import FormInput from "./atoms/FormInput";
import { client } from "../utils/client";
import { useDispatch } from "react-redux";
import { openAlert } from "../reducers/uiSlice";

interface Props {
  platforms: any[];
  setSelectedPlatform: React.Dispatch<any>;
  selectedPlatform: any;
  onVerify: (values: any) => void;
  setDisabledSaveConnector: React.Dispatch<any>;
}

var isOAuth: boolean = false;
var codeApp: string;
var btnOAuthUrl: string;
var userDefineOAuthUrl: string;
var tempCodeActivation: string;
var polling: any;
var extrafieldsInputs: any;

const Connectors: FunctionComponent<Props> = ({
  platforms,
  setSelectedPlatform,
  selectedPlatform,
  onVerify,
  setDisabledSaveConnector,
}) => {
  
  const [btnOAuthSrc, setOAuthBtnImg] = useState();
  const [isUserDefinedAuthUrl, setUserUrlDefined] = useState(false);
  const [isOAuthBtnDisabled, setBtnOAuthDisabled] = useState(false);
  const [extrafieldsRequired, setExtraFieldsRequired] = useState(false);
  const dispatch = useDispatch();

  const platformSelected = (event: ChangeEvent<HTMLInputElement>): any => {
    setExtraFieldsRequired(false);
    if (platforms) {
      let platform = platforms.find(function (obj: any) {
        return obj["_id"] === event.target.value;
      });
      setSelectedPlatform(platform);

      //Check if its an OAuth2 Connector
      if(platform.oauth2_settings){
        isOAuth = true;
        var btnSaveElem = document.getElementsByClassName("btnSaveConnector")[0] as HTMLElement;
        btnSaveElem!.style.display = "none";
      } else {
        isOAuth = false;
        setUserUrlDefined(false);
      }
        
      //Init OAuth2 Connectors
      codeApp = localStorage.getItem("code_app") ? localStorage.getItem("code_app")! : "";
      if(codeApp && isOAuth){
        client
        .get("api/init-new-o2-connector/" + codeApp + "/" + platform._id)
        .then(res => {
          if(res){
            btnOAuthUrl = res.data.button_url;
            tempCodeActivation = res.data.tempcode;
            setOAuthBtnImg(res.data.button_image);
            setUserUrlDefined(res.data.user_defined_url);
            if(res.data.user_defined_url && btnOAuthUrl && tempCodeActivation)
              setBtnOAuthDisabled(true);
            else
              setBtnOAuthDisabled(false);
          } else {
              console.log("-----------ERROR - INIT OAUTH CONNECTOR - RESPONSE UNDEFINED-------------");
              console.log(res);
          }
        }).catch(err => {
          console.log("-----------ERROR - INIT OAUTH CONNECTOR-------------");
          console.log(err);
        });
      }

    }
  };

  //Start Authentication for OAuth2 connectors
  function startOAuth(){
    //open auth window
    const windowName = 'Auth';
    const w = 700;
    const h = 700;
    const left = (window.screen.width / 2) - (w / 2);
    const top = (window.screen.height / 2) - (h / 2);
    const options = 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left;
    var url = isUserDefinedAuthUrl ? btnOAuthUrl.replace("{base_auth_url}", userDefineOAuthUrl) : btnOAuthUrl
    const authWindow = window.open(url, windowName, options);
    //start polling
    polling = setInterval(() => {
      checkConnectorStatus();
    }, 1000);
  }

  //Open app alert for errors
  function openErrorAlert(){
    dispatch(
      openAlert({
        content: "Errore durante la creazione del connettore",
        type: "danger",
      })
    );
  }

  //Check connector creation status
  function checkConnectorStatus(){
      client
        .get("api/status-new-o2-connector/" + tempCodeActivation)
        .then(res => {
          if(res){
            console.log("Connector status:");
            console.log(res.data.status);
            if(res.data.status == 'completed'){
              console.log("-----------CONNECTOR CREATED-------------");
              clearInterval(polling);
              connectorComplete();
            } else if(res.data.status == 'extra_fields'){
              console.log("-----------EXTRAFIELD REQUIRED-------------");
              extrafieldsInputs = res.data.additional_info_fields;
              setExtraFieldsRequired(true);
              clearInterval(polling);
            }
          } else {
              console.log("-----------ERROR - CHECK CONNECTOR STATUS - RESPONSE UDENFINED-------------");
              console.log(res);
              openErrorAlert();
          }
        }).catch(err => {
          console.log("-----------ERROR - CHECK CONNECTOR STATUS-------------");
          console.log(err);
          openErrorAlert();
        });
  }

  //Handle input on user custom auth url field
  const onInputOAuthUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    var inputContent = event.target.value;
    if(inputContent && inputContent.length > 0 && isValidUrl(inputContent)){
      setBtnOAuthDisabled(false);
      userDefineOAuthUrl = inputContent;
    } else {
      setBtnOAuthDisabled(true);
    }
  }

  //Check if a string is a valid URL
  function isValidUrl(urlString: string) {
    try { 
      return Boolean(new URL(urlString)); 
    }
    catch(e){ 
      return false; 
    }
  }

  //Send requested extrafields
  function sendExtraFields(){
    var arrayExtrafields = [];

    for (var i = 0; i < extrafieldsInputs.length; i++){
      var element = document.getElementById(extrafieldsInputs[i].nameField);
      var castedElement = element as HTMLInputElement;
      if(castedElement != null){
        var obj = {
          [extrafieldsInputs[i].nameField] : castedElement.value
        }
        arrayExtrafields.push(obj);
      }
    };

    client
        .post("api/update-status-new-o2-connector" + tempCodeActivation, arrayExtrafields)
        .then(res => {
          if(res){
            console.log("---EXTRA FIELDS SENT SUCCESSFULLY---");
            setExtraFieldsRequired(false);
            polling = setInterval(() => {
              checkConnectorStatus();
            }, 1000);
          } else {
              console.log("-----------ERROR - PROVIDE EXTRA FIELDS FOR CONNECTION - RESPONSE UDENFINED-------------");
              console.log(res);
              openErrorAlert();
          }
        }).catch(err => {
          console.log("-----------ERROR - PROVIDE EXTRA FIELDS FOR CONNECTION-------------");
          console.log(err);
          openErrorAlert();
        });
  }
  
  //On connection complete
  function connectorComplete(){
    dispatch(
      openAlert({
        content: "Connessione effettuata con successo.",
        type: "success",
      })
    );
    setTimeout(function(){ window.location.reload() }, 2000);
  }

  useEffect(() => {
  },[]);

  function getInputField(inputName: string) {
    let inputType;
    switch (true) {
      case inputName.toUpperCase().indexOf("password".toUpperCase()) !== -1:
        inputType = "password";
        break;
      case inputName.toUpperCase().indexOf("secret".toUpperCase()) !== -1:
        inputType = "password";
        break;
      default:
        inputType = "text";
        break;
    }
    return inputType;
  }

  return (
    <>
      <Flex gap="gap.small" padding="padding.medium" className="containerflex">
        <Flex.Item size="size.half" align="center">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12">
            <div className="containerLogoConnectors">
              <img
                alt="logo"
                style={{ fontWeight: "50%" }}
                src={process.env.PUBLIC_URL + "/" + "callbixLogo1.png"}
              />
            </div>
            
            <Flex styles={{ flexWrap: "wrap" }}>
              {platforms.map((plat: any) => {
                return (
                  <SelectIcons
                    key={plat._id}
                    platform={plat}
                    onChange={platformSelected}
                  />
                );
              })}
            </Flex>
          </div>
        </Flex.Item>
        <Flex.Item size="size.half">
          <Flex column>
            <Flex.Item>
              <h3 style={{ textAlign: "center" }}>Compila il form o clicca su Accedi per connettere la tua app</h3>
            </Flex.Item>
            <Flex.Item>
              <Form styles={{ height: "auto" }}>

                {isUserDefinedAuthUrl && (
                  <FormInput onInput={onInputOAuthUrl} type="text" label="URL" name="oauth_user_url" id="OAuthUserUrl" style={{ marginBottom: "1.25rem" }}/>
                )}

                {isOAuth && btnOAuthSrc && (
                  <Button disabled={isOAuthBtnDisabled} onClick= { startOAuth } id="btnOAuth" type="button" styles={{ width: "200px", marginRight: "auto", marginLeft: "auto", marginTop: "30px !important", marginBottom: "50px !important" }}>
                    <img src={btnOAuthSrc} width="220px" alt="OAuth2 Authentication" />
                  </Button>
                )}

                {extrafieldsRequired && (
                  <div>
                    <h4 style={{ textAlign: "center" }}>Sono richiesti dei campi aggiuntivi</h4>
                  </div>
                )}

                {extrafieldsRequired && extrafieldsInputs?.map(
                  (extrafield: any, i: number) => (
                    extrafield.type == 'input' ?

                    <FormInput
                      key={extrafield.nameField}
                      label={extrafield.label}
                      name={extrafield.nameField}
                      type={'text'}
                      id={extrafield.nameField}
                    />

                    :

                    <div key={extrafield.nameField}>
                    <label>{extrafield.label}</label>
                    <select id={extrafield.nameField} name={extrafield.nameField} style={{ display: "block", padding: "4px", marginTop: "5px" }}>
                      {extrafield.items.map(
                        (item: any) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                        )
                      )}
                    </select>
                    </div>
                  )
                )}

                {extrafieldsRequired && (
                  <Button onClick= { sendExtraFields } id="btnSendExtraField" type="button" styles={{ marginRight: "auto", marginLeft: "auto", marginTop: "30px !important", marginBottom: "auto", display: "block" }}>
                  Invia
                  </Button>
                )}

                {!isOAuth && selectedPlatform && (
                  <div>
                  <FormInput label="Name" name="name" style={{ marginBottom: "1.25rem" }}/>
                  <FormInput label="URL" name="api_url" />
                  </div>
                )}

                {!isOAuth && selectedPlatform?.variables?.map(
                  (field: string, idx: number) => (
                    <FormInput
                      key={field + idx}
                      label={field}
                      name={field}
                      type={getInputField(field)}
                      disabled={selectedPlatform?.variables_disabled?.includes(
                        field
                      )}
                    />
                  )
                )}

                {!isOAuth && selectedPlatform &&  (
                  <Button size="small" onClick={onVerify} type="button" styles={{ marginRight: "auto", marginLeft: "auto" }}>
                  Verifica connessione
                </Button>
                )}

              </Form>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default Connectors;
