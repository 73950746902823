import { Alert, AlertProps } from "@fluentui/react-northstar";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../interfaces/State";
import { closeAlert } from "../../reducers/uiSlice";

const AlertResponse: FunctionComponent<AlertProps> = (props) => {
  const uiState = useSelector((state: State) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uiState.alertContent && !uiState.alertKeepOpen) {
      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);
    }
  }, [dispatch, uiState.alertContent, uiState.alertKeepOpen]);

  const produceType = () => {
    if (uiState.alertType === "success") {
      return {
        success: true,
      };
    }

    if (uiState.alertType === "warning") {
      return {
        warning: true,
      };
    }

    if (uiState.alertType === "danger") {
      return {
        danger: true,
      };
    }
  };

  if (!uiState.alertContent) {
    return null;
  }

  return (
    <Alert
      onVisibleChange={() => {
        dispatch(closeAlert());
      }}
      style={{
        zIndex: 9999,
        position: "fixed",
        bottom: 10,
        left: 10,
        width: 250,
      }}
      dismissible
      content={uiState.alertContent}
      dismissAction={{
        "aria-label": "close",
      }}
      {...produceType()}
      {...props}
    />
  );
};

export default AlertResponse;
