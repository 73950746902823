import { useEffect, useState } from "react";
import { Checkbox, Button } from "@fluentui/react-northstar";
import "../index.css";
import "./Settings.css";
import { client } from "../utils/client";
import HeaderTab from "./atoms/HeaderTab";
import { useDispatch } from "react-redux";
import { openAlert } from "../reducers/uiSlice";

const Settings = () => {

    const [numCharSmsText, setNumCharSmsText] = useState<any>("140");

    const [userSettingsReceived, setUserSettingsReceived] = useState<any>(false);
    const [settingsError, setSettingsError] = useState<any>(false);

    const [whatsappLabel, setWhatsappLabel] = useState<any>("");
    const [whatsappValue, setWhatsappValue] = useState<any>(false);

    const [smsLabel, setSmsLabel] = useState<any>("");
    const [smsValue, setSmsValue] = useState<any>(false);

    const [landingPageLabel, setLandingPageLabel] = useState<any>("");
    const [landingPageOptions, setLandingPageOptions] = useState<any>([]);
    var landingPageLink = "";
    const [landingPageResponseOptions, setLandingPageResponseOptions] = useState<any>("");
    
    const [smsTextLabel, setSmsTextLabel] = useState<any>("");
    const [smsTextValue, setSmsTextValue] = useState<any>("");

    const [cardLabel, setCardLabel] = useState<any>("");
    const [cardValue, setCardValue] = useState<any>(false);

    const [teamLabel, setTeamLabel] = useState<any>("");
    const [teamOptions, setTeamOptions] = useState<any>([]);
    var teamResponseOptions: any = [];

    const [channelLabel, setChannelLabel] = useState<any>("");
    const [channelOptions, setChannelOptions] = useState<any>([]);
    var channelResponseOptions: any = [];

    const [teamRequired, setTeamRequired] = useState<any>(false);
    const [channelRequired, setChannelRequired] = useState<any>(false);
    const [maxCharError, setMaxCharError] = useState<any>(false);


    //TEST---------------------------------
    // var test = {
    //     status: "OK",
    //     data: {
    //         sms: {
    //             "enabled": true
    //         },
    //         landingPageInformativa: {
    //             enabled: true
    //         },
    //         landingPageMotivo: {
    //             enabled: true
    //         },
    //         landingPageBooking: {
    //             enabled: true
    //         },
    //         chatbot: {
    //             enabled: true
    //         },
    //         riconoscimentoChiamante: {
    //             enabled: true
    //         },
    //         whatsapp: {
    //             enabled: true
    //         }
    //     }
    // }
    //-------------------------------------------

    const userOptions = JSON.parse(localStorage.getItem("user_options")!); //TEST-> = test;


    

    const dispatch = useDispatch();

    function getSettings(){
        client
        .get("api/teams/v1/callbix/getsettings")
        .then((response: any) => {
            if(response && response.data && response.data.data){
                setUserSettingsReceived(true);
                //set whatsapp
                setWhatsappLabel(response.data.data.whatsapp.label);
                setWhatsappValue(response.data.data.whatsapp.value);
                //set sms
                setSmsLabel(response.data.data.smsEnabled.label);
                setSmsValue(response.data.data.smsEnabled.value);
                //set landing page
                setLandingPageLabel(response.data.data.landingpage.label);
                var responseLandingOpt = response.data.data.landingpage.options;
                setLandingPageResponseOptions(JSON.stringify(responseLandingOpt));
                var initialSelectedId = response.data.data.landingpage.selectedId;
                var defaultObj = {id: null, name: null, selected: false}
                var dropdownOptions: any = [defaultObj];
                responseLandingOpt.forEach((value: any, idx: number) => {
                    var obj = {
                        id: value.id,
                        name: value.label,
                        selected: value.id == initialSelectedId
                    }
                    dropdownOptions.push(obj);
                    if(value.id == initialSelectedId && value.link)
                        landingPageLink = value.link;
                });
                setLandingPageOptions(dropdownOptions);
                //set text sms
                setSmsTextLabel(response.data.data.smsText.label);
                var finalString = response.data.data.smsText.value;
                setSmsTextValue(finalString);
                handleReaminsChar(finalString);
                if (userOptions.data.riconoscimentoChiamante.enabled) {
                    //set card
                    setCardLabel(response.data.data.riconoscimentoChiamante.label);
                    setCardValue(response.data.data.riconoscimentoChiamante.value);
                    //set team
                    setTeamLabel(response.data.data.teams.label);
                    teamResponseOptions = response.data.data.teams.options;
                    var initialTeamId = response.data.data.teams.selectedId;
                    var dropdownTeamOptions: any = [];
                    teamResponseOptions.forEach((value: any, idx: number) => {
                        var obj = {
                            id: value.id,
                            name: value.label,
                            selected: value.id == initialTeamId
                        }
                        dropdownTeamOptions.push(obj);
                    });
                    setTeamOptions(dropdownTeamOptions);
                    //set channel
                    setChannelLabel(response.data.data.teamsChannels.label);
                    channelResponseOptions = response.data.data.teamsChannels.options;
                    var initialChannelId = response.data.data.teamsChannels.selectedId;
                    var dropdownChannelOptions: any = [];
                    channelResponseOptions.forEach((value: any, idx: number) => {
                        var obj = {
                            id: value.id,
                            name: value.label,
                            selected: value.id == initialChannelId
                        }
                        dropdownChannelOptions.push(obj);
                    });
                    setChannelOptions(dropdownChannelOptions);
                }
                if (localStorage.getItem('alreadyReloaded') === "false" || !localStorage.getItem('alreadyReloaded') ) {
                    localStorage.setItem('alreadyReloaded', 'true');
                    console.log("reloaded");
                    window.location.reload();
                }
                setSettingsError(false);
            } else{
                console.log("-----------ERROR - GET SETTINGS-------------");
                console.log(response);
                //openAppAlert(false);
                setSettingsError(true);
            }
        }).catch(function (error: any) {
            console.log("-----------ERROR - GET SETTINGS-------------");
            console.log(error);
            //openAppAlert(false);
            setSettingsError(true);
        });
    }

    useEffect(() => {
        getSettings();
    }, []);

    //Handle input on sms text
    const handleSmsText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var inputContent = event.target.value;
        handleReaminsChar(inputContent);
    }

    //Handle remains char text
    function handleReaminsChar(content: string){
        var remainingChar = 140 - content.length;
        setNumCharSmsText(remainingChar.toString());
    }

    //Handle landing page on change
    const handleLandingPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var selectedVal = event.target.value;
        if(selectedVal){
            var link = "";
            var responseOptions = JSON.parse(landingPageResponseOptions);
            responseOptions.forEach((opt: any, idx: number) => {
                if(opt.id == selectedVal)
                    link = opt.link;
            });
            if(link)
                changeSmsTextUrl(link);
        }
        else
            changeSmsTextUrl("");
    }

    //Change sms text url on landing page changing
    function changeSmsTextUrl(newUrl: string){
        var textElement = document.getElementById("inputTextSms");
        var castedTextElement = textElement as HTMLTextAreaElement;
        var currentText = castedTextElement!.value;
        var urlRegex = /(https?:\/\/[^ ]*)/;
        var url = currentText.match(urlRegex) != null ? currentText.match(urlRegex)![1] : "";
        var finalString = "";
        var baseString = currentText;
        var appendString = (newUrl != "" ? " " + newUrl : "");
        if(url){
            finalString = baseString.replace(" " + url, appendString);
            baseString = baseString.replace(" " + url, "");
        }
        else
            finalString = baseString + appendString;
        if(finalString.length > 140)
            finalString = baseString.substring(0,(140 - appendString.length)) + appendString;
        setSmsTextValue(finalString);
        handleReaminsChar(finalString);
        castedTextElement!.value = finalString;
    }

    //Handle team on change 
    const handleTeam = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var selectedVal = event.target.value;
        if(selectedVal != null){
            client
            .post("api/teams/v1/callbix/getteamschannels", {teamsId: selectedVal})
            .then((response: any) => {
                if(response && response.data && response.data.data){
                    channelResponseOptions = response.data.data.channelOptions;
                    var dropdownChannelOptions: any = [];
                    channelResponseOptions.forEach((value: any, idx: number) => {
                        var obj = {
                            id: value.id,
                            name: value.label,
                            selected: false
                        }
                        dropdownChannelOptions.push(obj);
                    });
                    setChannelOptions(dropdownChannelOptions);
                }
                else {
                    console.log("-----------ERROR - GET CHANNELS-------------");
                    console.log(response);
                    setChannelOptions([]);
                }
            }).catch(function (error: any) {
                console.log("-----------ERROR - GET CHANNELS-------------");
                console.log(error);
                openAppAlert(false);
            });
        }
        else
            setChannelOptions([]); 
    }

    //Send and save settings
    function saveSettings(){
        //whatsapp
        var whatsapp = false;
        if(userOptions.data.whatsapp.enabled)
            whatsapp = whatsappValue;
        //sms
        var sms = false;
        if(userOptions.data.sms.enabled)
            sms = smsValue;
        //landing page 
        var landingpage = null;
        if(userOptions.data.landingPageInformativa.enabled || userOptions.data.landingPageMotivo.enabled || userOptions.data.landingPageBooking.enabled || userOptions.data.chatbot.enabled){
            var landingpageElem = document.getElementById("selectLandingPage") as HTMLInputElement;
            landingpage = landingpageElem.value;
        }
        //sms text
        var smsText = null;
        if(userOptions.data.sms.enabled){
            var smsTextElem = document.getElementById("inputTextSms") as HTMLTextAreaElement;
            smsText = smsTextElem.value;
        }
        //card
        var card = false;
        if(userOptions.data.riconoscimentoChiamante.enabled)
            card = cardValue;
        //team
        var team = null;
        if(userOptions.data.riconoscimentoChiamante.enabled){
            var teamElem = document.getElementById("selectTeam") as HTMLInputElement;
            team = teamElem.value;
        }
        //channel
        var channel = null;
        if(userOptions.data.riconoscimentoChiamante.enabled){
            var channelElem = document.getElementById("selectChannel") as HTMLInputElement;
            channel = channelElem.value;
        }
        var err = false;
        if(!team && userOptions.data.riconoscimentoChiamante.enabled){
            setTeamRequired(true);
            err = true;
        }
        if(!channel && userOptions.data.riconoscimentoChiamante.enabled){
            setChannelRequired(true);
            err = true;
        }
        if(userOptions.data.sms.enabled && smsText && smsText.length > 140){
            setMaxCharError(true);
            err = true;
        }

        //send
        if(!err){
            var requestBody = {
                whatsapp: whatsapp,
                smsEnabled: sms,
                landingpageId: landingpage,
                smsText: smsText,
                riconoscimentoChiamante: card,
                teamsId: team,
                teamsChannelId: channel
            }
    
            client
            .post("api/teams/v1/callbix/setsettings", requestBody)
            .then((response: any) => {
                if(response && response.data){
                    openAppAlert(true);
                }
                else {
                    console.log("-----------ERROR - SET SETTINGS-------------");
                    console.log(response);
                    openAppAlert(false);
                }
            }).catch(function (error: any) {
                console.log("-----------ERROR - SET SETTINGS-------------");
                console.log(error);
                openAppAlert(false);
            });
        }
    }

    //Handle app alert messages
    function openAppAlert(success: boolean){
        dispatch(
          openAlert({
            content: (success ? "Impostazioni salvate con successo" : "Si è verificato un errore imprevisto"),
            type: (success ? "success" : "danger"),
          })
        );
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <HeaderTab></HeaderTab>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12" style={{ marginTop: "20px" }}>
                    <div className="ms-Grid-col ms-md4 ms-lg4 ms-xs4">
                    <h1>Impostazioni</h1>
                    </div>
                </div>
            </div>
            {settingsError && (
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12" style={{ marginTop: "20px" }}>
                        <div className="ms-Grid-col ms-md12 ms-lg12 ms-xs12">
                            <span>Per ottenere accesso a questa sezione, completa la configurazione su <a style={{ color: "#b6cf58" }} href="https://panel.callbix.it" target="_blank" rel="noreferrer">https://panel.callbix.it</a>.</span>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12" style={{ marginTop: "20px" }}>
                        <div className="ms-Grid-col ms-md12 ms-lg12 ms-xs12">
                            <span>Contattaci su <a style={{ color: "#b6cf58" }} href="https://www.callbix.it/contatti" target="_blank" rel="noreferrer">https://www.callbix.it/contatti</a>, se hai bisogno di assistenza.</span>
                        </div>
                    </div>
                </div>
            )}
            {userSettingsReceived && (
                <div className="settingsDiv marginTabElements">

                    {/* WHATSAPP */}
                    {userOptions.data.whatsapp.enabled && 
                        <>
                        <div className="childDivSettings"> 
                            <Checkbox onChange={() => setWhatsappValue(!whatsappValue)}  id="whatsappToogle" label={whatsappLabel} checked={whatsappValue} labelPosition="start" variables={{ checkedBackground: "#b6cf56", checkedBackgroundHover: "#b6cf56" }} toggle />
                        </div>
                        <br/>
                        </>
                    }
                    

                    {/* SMS */}
                    {userOptions.data.sms.enabled && 
                        <>
                        <div className="childDivSettings"> 
                            <Checkbox onChange={() => setSmsValue(!smsValue)}  id="smsToogle" label={smsLabel} checked={smsValue} labelPosition="start" variables={{ checkedBackground: "#b6cf56", checkedBackgroundHover: "#b6cf56" }} toggle />
                        </div>
                        <br/> 
                        </>
                    }
                    

                    {/* LANDING PAGE */}
                    {(userOptions.data.landingPageInformativa.enabled || userOptions.data.landingPageMotivo.enabled || userOptions.data.landingPageBooking.enabled || userOptions.data.chatbot.enabled) && 
                        <>
                        <div className="childDivSettings flex"> 
                            <div className="ui-checkbox bf fy fz ga ci cl gb gc gd ge gf ch gg dn gh gi gj gk do dp dq dr ds dt du dv dw dx dy gl gm gn go ed ee ef eg eh gp gq gr gs em en eo ep eq er es et eu ev ew gt gu gv gw fc fd fe ff fg gx gy gz ha cz hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz ia ib ic id ie">
                                <span className="ui-text be if ig ui-checkbox__label">{landingPageLabel}</span>
                            </div>
                            <select onChange={handleLandingPage} id="selectLandingPage" name="selectLandingPage" className="dropdownSettings backgroundColorBlue colorWhite">
                                {landingPageOptions.map(
                                    (item: any) => (
                                    <option key={item.id} value={item.id} selected={item.selected}>{item.name}</option>
                                    )
                                )}
                            </select>
                        </div>
                        </>
                    }
                    

                    {/* TEXT SMS */}
                    {userOptions.data.sms.enabled && 
                        <>
                        <div className="childDivSettings" style={{marginTop: "15px"}}> 
                            <div className="ui-checkbox bf fy fz ga ci cl gb gc gd ge gf ch gg dn gh gi gj gk do dp dq dr ds dt du dv dw dx dy gl gm gn go ed ee ef eg eh gp gq gr gs em en eo ep eq er es et eu ev ew gt gu gv gw fc fd fe ff fg gx gy gz ha cz hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz ia ib ic id ie">
                                <span className="ui-text be if ig ui-checkbox__label">{smsTextLabel}</span>
                            </div>
                            <div className="ui-checkbox bf fy fz ga ci cl gb gc gd ge gf ch gg dn gh gi gj gk do dp dq dr ds dt du dv dw dx dy gl gm gn go ed ee ef eg eh gp gq gr gs em en eo ep eq er es et eu ev ew gt gu gv gw fc fd fe ff fg gx gy gz ha cz hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz ia ib ic id ie">
                                <textarea
                                    className="textAreaSettings"
                                    key="inputTextSms"
                                    name="inputTextSms"
                                    id="inputTextSms"
                                    maxLength={140}
                                    // rows={273}
                                    // style={{ width:"500px", height:"60px"}}
                                    defaultValue={smsTextValue}
                                    onInput={handleSmsText}
                                    />
                            </div>
                            <div className="textAlignEnd">
                                {numCharSmsText} caratteri rimanenti
                            </div>
                        </div>
                        {maxCharError && (
                            <div className="fieldRequiredDiv">
                                <span className="textWarning">Sono consentiti massimo 140 caratteri</span>
                            </div>
                        )}
                        <br/> 
                        </>
                    }
                    

                    {/* CARD */}
                    {userOptions.data.riconoscimentoChiamante.enabled && 
                        <>
                        <div className="childDivSettings"> 
                            <Checkbox onChange={() => setCardValue(!cardValue)} id="cardToogle" label={cardLabel} checked={cardValue} labelPosition="start" variables={{ checkedBackground: "#b6cf56", checkedBackgroundHover: "#b6cf56" }} toggle />
                        </div>
                        <br/> 
                        </>
                    }
                    
                    
                    {/* TEAM */}
                    {userOptions.data.riconoscimentoChiamante.enabled && 
                        <>
                        <div className="childDivSettings flex"> 
                            <div className="ui-checkbox bf fy fz ga ci cl gb gc gd ge gf ch gg dn gh gi gj gk do dp dq dr ds dt du dv dw dx dy gl gm gn go ed ee ef eg eh gp gq gr gs em en eo ep eq er es et eu ev ew gt gu gv gw fc fd fe ff fg gx gy gz ha cz hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz ia ib ic id ie">
                                <span className="ui-text be if ig ui-checkbox__label">{teamLabel}</span>
                            </div>
                            <select onChange={handleTeam} id="selectTeam" name="selectTeam" className="dropdownSettings backgroundColorBlue colorWhite">
                                {teamOptions.map(
                                    (item: any) => (
                                    <option key={item.id} value={item.id} selected={item.selected}>{item.name}</option>
                                    )
                                )}
                            </select>
                            
                        </div>
                        {teamRequired && (
                            <div className="fieldRequiredDiv">
                                <span className="textWarning">Il team deve essere selezionato</span>
                            </div>
                        )}
                        <br/> 
                        </>
                    }
                    

                    {/* CHANNEL */}
                    {userOptions.data.riconoscimentoChiamante.enabled && 
                        <>
                        <div className="childDivSettings flex"> 
                            <div className="ui-checkbox bf fy fz ga ci cl gb gc gd ge gf ch gg dn gh gi gj gk do dp dq dr ds dt du dv dw dx dy gl gm gn go ed ee ef eg eh gp gq gr gs em en eo ep eq er es et eu ev ew gt gu gv gw fc fd fe ff fg gx gy gz ha cz hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs ht hu hv hw hx hy hz ia ib ic id ie">
                                <span className="ui-text be if ig ui-checkbox__label">{channelLabel}</span>
                            </div>
                            <select id="selectChannel" name="selectChannel" className="dropdownSettings backgroundColorBlue colorWhite">
                                {channelOptions.map(
                                    (item: any) => (
                                    <option key={item.id} value={item.id} selected={item.selected}>{item.name}</option>
                                    )
                                )}
                            </select>
                        </div>
                        {channelRequired && (
                            <div className="fieldRequiredDiv">
                                <span className="textWarning">Il canale deve essere selezionato</span>
                            </div>
                        )}
                        <br/> 
                        </>
                    }
                    
                    {/* SAVE BTN */}
                    <Button onClick={saveSettings} className="settingsBtn" id="sendSettingsBtn" content="Salva" variables={{backgroundColor: "#2492eb", backgroundColorHover: "#2492eb"}} />
                    
                </div>
            )}
        </div>
    );
};
export default Settings;