import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import Tab from "./Tab";
import "./App.css";
import useAxiosInterceptor from "./hooks/useAxiosInterceptor";
import LoadingModal from "./organisms/LoadingModal";
import Workflow from "./Workflow";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store/store";
import AlertResponse from "./organisms/AlertResponse";
import CallListTab from "./CallListTab"
import SettingsTab from "./SettingsTab"

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, loading: teamsLoading } = useTeamsFx();
  const { loading, configReady } = useAxiosInterceptor();
  const userOptions = localStorage.getItem("user_options") != null ? JSON.parse(localStorage.getItem("user_options")!) : null;

  var riconoscimentoChiamanteEnabled = false;
  if(userOptions && userOptions.data.riconoscimentoChiamante.enabled)
      riconoscimentoChiamanteEnabled = true;

  if (!configReady) {
    return null;
  }

  return (
    <ReduxProvider store={store}>
      <Provider
        theme={theme || teamsTheme}
        // styles={{ backgroundColor: "#eeeeee" }}
        styles={{ backgroundColor: "transparent" }}
      >
        <LoadingModal loading={!!loading} />
        <AlertResponse />

        <Router>
          <Route exact path="/">
              <Redirect to="/tab" />
          </Route>
          {teamsLoading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path="/tab" component={Tab} /> 
              <Route exact path="/call_list" component={CallListTab} />
              <Route exact path="/settings" component={SettingsTab} />
              <Route exact path="/workflows" component={Workflow} />
            </>
          )}
        </Router>
      </Provider>
    </ReduxProvider>
  );
}
