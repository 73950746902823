import { Button } from "@fluentui/react-northstar";

function logout() {
  localStorage.clear();
  window.location.reload();
}
const HeaderTab = () => {
  const userName = "";

  return (
    <div className="ms-Grid-row">
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 backgroundColorBlue colorWhite topBar">
        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xs3 textCenter headerLogoDiv">
          <img
            className="headerLogo"
            alt="Callbix"
            style={{ fontWeight: "50%" }}
            src={process.env.PUBLIC_URL + "/" + "callbix_logo_mail.png"}
          />
        </div>

        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-lgoffset1 ms-xs6 textCenter headerTextDiv">
          <span className="colorWhite">
            {" "}
            Salve{" "}
            <span className="colorGreen">
              {localStorage.getItem("userName")}
            </span>
            , ora puoi gestire il tuo VOIP in teams. {" "}
          </span>
        </div>
        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2  ms-xs2 textCenter headerBtnDiv">
          <Button
            onClick={logout}
            type="button"
            content="Logout"
            style={{ backgroundColor: "#2492eb", color: "white", minWidth: "60px"}}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderTab;
