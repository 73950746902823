import { useState } from "react";
import Login from "./Login";
import Dashboard from "./Dashboard";
import CallList from "./CallList"

export default function Tab() {
  const [authentication, setAuthentication] = useState(
    localStorage.getItem("activation_token") ? true : false
  );

  const userOptions = localStorage.getItem("user_options") != null ? JSON.parse(localStorage.getItem("user_options")!) : null;

  if (!authentication) {
    return (
      <Login
        onLoginSuccess={() => {
          setAuthentication(true);
        }}
      />
    );
  }

  return <Dashboard />;
}
