import { Flex, Input, Label } from "@fluentui/react-northstar";
import { env } from "process";
import React, {
  ChangeEvent,
  Component,
  FunctionComponent,
  MouseEventHandler,
  useMemo,
  useState,
} from "react";
import "./SelectIcons.css";
import { client } from "../utils/client";
import { platform } from "os";

interface Props {
  platform: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SelectIcons: FunctionComponent<Props> = ({ platform, onChange }) => {
  return (
    <Flex.Item size="size.half">
      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4  ms-xs6">
        <input
          className="hidden"
          id={"module_" + platform._id}
          type="radio"
          name="test"
          value={platform._id}
          onChange={onChange}
        ></input>
        <label
          htmlFor={"module_" + platform._id}
          style={{ textAlign: "center", width: "100%", paddingBottom: "10px" }}
        >
          <img
            src={process.env.REACT_APP_API_URL + "/" + platform.img_btn}
            style={{ marginTop: "10px", width: "119px", height: "65px" }}
          ></img>
          <label
            style={{
              width: "119px",
              textAlign: "center",
              marginBottom: "10px",
              display: "block",
            }}
          >
            {platform.name}
          </label>
        </label>
      </div>
    </Flex.Item>
  );
};

export default SelectIcons;
