import { useState, useEffect } from "react";
import "./Login.css";
import { client } from "../utils/client";
import {
  createMicrosoftGraphClientWithCredential,
  TeamsUserCredential,
  TeamsUserCredentialAuthConfig
 } from "@microsoft/teamsfx";
 import { LockIcon, Label, Form, Input, FormButton, Alert, ExclamationTriangleIcon, UserBlurIcon } from "@fluentui/react-northstar";

function Login(props: { onLoginSuccess: () => void }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const scope = ["User.Read"];
  const [oidUser, setOIDUser] = useState("");

  const authConfig: TeamsUserCredentialAuthConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
  };
    
  const teamsUserCredential = new TeamsUserCredential(authConfig);

  async function autoLogin() {
    try{
      const graphClient = createMicrosoftGraphClientWithCredential(teamsUserCredential, scope); 
      const profile = await graphClient.api("/me").get();
      var response = null;
      if(profile){
        setOIDUser(profile.id);

        let conf : any= {};
        conf.validateStatus = (status: number) => {
            return (status >= 200 && status < 300) || status === 404
        }
        response = await client.post(`api/teams/v1/callbix/ssologin`, {
          oid: profile.id //oid test: '05833b6b-aa1d-42d4-9ec0-1b2bb9194438'
        }, conf);
      }
      return response;
    }
    catch (err: unknown) {
      console.log("-----------ERROR - LOGIN-------------");
      console.log(err);
      return null;
    } 
  }

  useEffect(() => {
    autoLogin().then(responseLogin => {
      //Handle auto login response
      if(responseLogin){
        if(responseLogin.status >= 200 && responseLogin.status < 300 && responseLogin.data.activation_token){
          localStorage.setItem(
                    "activation_token",
                    responseLogin.data.activation_token
          );

          localStorage.setItem(
                  "code_app",
                  responseLogin.data.activation_id //codeapp test: "2yIoUeoUOsqMyW4x"
          );
          getUserOptions();
        }
      }
    }).catch(function (error: any) {
      console.log("-----------ERROR - AUTOLOGIN-------------");
      console.log(error);
    });
  }, []);

  //Handle login by credential
  function handleLoginSubmit(){
    const data = { "email": email, "password": password, "oid": oidUser };
    client.post(`api/teams/v1/callbix/login`, data).then(res => {
      if(!res.data.access){
        console.log('login failed');
        setAlert(true);
      } else {
        console.log('login success');
        localStorage.setItem(
          "activation_token",
          res.data.data.activation_token
        );

        localStorage.setItem(
          "code_app",
          res.data.data.activation_id
        );
        getUserOptions()
      }
    }).catch(function (error: any) {
      setAlert(true);
      console.log("-----------ERROR - LOGIN-------------");
      console.log(error);
    });
  }

  //Get user options after login
  function getUserOptions(){
    client.get(`api/teams/v1/callbix/options`).then(res => {
      localStorage.setItem(
        "user_options",
        JSON.stringify(res.data)
      );
      props.onLoginSuccess();
    }).catch(function (error: any) {
      setAlert(true);
      console.log("-----------ERROR - GET USER OPTIONS-------------");
      console.log(error);
    });
  }

  return (
    <>
      <Form onSubmit={handleLoginSubmit} style={{ justifyContent: "normal" }}>
           <div className="ms-Grid" dir="ltr">
               <div className="ms-Grid-row">
                   <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12">
                       <div className="ms-Grid-col ms-lgoffset2 ms-lg8 ms-xs4" style={{ alignContent: "center" }}>
                           <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 textCenter" style={{ marginTop: "15px", textAlign: "center" }}>
                               <img alt="logo" style={{ fontWeight: "50%" }} src={process.env.PUBLIC_URL + "/" + "callbixLogo1.png"}></img>
                           </div>
                           <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-lgoffset3 ms-xs12 textCenter" style={{ marginTop: "15px", textAlign: "center" }}>
                               <p>Gestisci le conversazioni con i tuoi clienti grazie a TEAMS e al Call Assistant Callbix</p>
                           </div>
                           <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-lgoffset2 ms-xs12 textCenter" style={{ marginTop: "15px", backgroundColor: "#2492eb", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "30px" }}>
                               <div className="ms-Grid-row">
                                   <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12  ms-xs12 textCenter" style={{ marginTop: "15px", color: "white" }}>
                                       <h3>Effettua il login a Callbix</h3>
                                   </div>
                               </div>
                               <div className="ms-Grid-row">
                                   <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12" >

                                       <Input label={<Label content="Username" icon={<UserBlurIcon />} iconPosition="start" style={{ backgroundColor: "#b6cf58", color: "white", height: "32px", width: "100px", borderTopRightRadius: "0", borderBottomRightRadius: "0" }} />}
                                           labelPosition="inline" name="email"
                                           id="email"
                                           key="email"
                                           onChange={(e) => setEmail((e.target as HTMLTextAreaElement).value)}
                                          required
                                          showSuccessIndicator={false}
                                          style={{ borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }} />
                                  </div>
                              </div>
                              <div className="ms-Grid-row">
                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12" style={{ marginTop: "20px" }}>
                                      <Input label={<Label content="Password" icon={<LockIcon />} iconPosition="start" style={{ backgroundColor: "#b6cf58", color: "white", height: "32px", width: "100px", borderTopRightRadius: "0", borderBottomRightRadius: "0" }} />}
                                          labelPosition="inline" name="password"
                                          id="password"
                                          key="password"
                                          type='password'
                                          onChange={(e) => setPassword((e.target as HTMLTextAreaElement).value)}
                                          required
                                          showSuccessIndicator={false}
                                          style={{ borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }}
                                      />

                                  </div>
                              </div>
                              <div className="ms-Grid-row" style={{ marginTop: "40px" }}>
                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 " style={{ textAlign: "right" }}>
                                      <FormButton content="Login" style={{ backgroundColor: "#b6cf58", color: "white", width: "30%" }} />
                                  </div>
                              </div>
                              <div className="ms-Grid-row" style={{ marginTop: "20px" }}>
                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 " style={{ textAlign: "center", marginTop: "10px" }} >
                                      <span className="colorWhite">Hai dimenticato l'username o la password? </span><a style={{ color: "#b6cf58" }} href="https://panel.callbix.it/password/reset" target="_blank" rel="noreferrer">Clicca qui </a>
                                  </div>
                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 " style={{ textAlign: "center", marginTop: "10px" }} >
                                      <span className="colorWhite">Vuoi maggiori informazioni su Callbix? </span><a style={{ color: "#b6cf58" }} href="https://panel.callbix.it" target="_blank" rel="noreferrer">Clicca qui </a>
                                  </div>
                              </div>
                             


                          </div>
                          <div className="ms-Grid-row" >
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-lgoffset2 ms-xs12" style={{marginTop: "20px"}} >
                                 {alert ?<Alert danger  icon={<ExclamationTriangleIcon />} content="Username o password errati. Ti preghiamo di riprovare" /> :null} 
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </Form>
    </>
  )
}

export default Login;