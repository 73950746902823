  import { useEffect, useState } from "react";
  import { Table } from "@fluentui/react-northstar";
  import "../index.css";
  import "./Dashboard.css";
  import { client } from "../utils/client";
  import HeaderTab from "./atoms/HeaderTab";

const CallList = () => {

    const [rows, setRows] = useState<any>([]);
    const [headers, setHeaders] = useState<any>([]);

    //Get calls and populate table
    function getCalls(){
      client
        .get("api/teams/v1/callbix/getcalls")
        .then((response: any) => {
          if(response && response.data && response.data.data){
            response = response.data;

            //TEST------------------------
          //   response = {
          //     status: "OK",
          //     data: {
          //         polling_interval: 0,
          //         headers: [
          //             "Chiamante",
          //             "Data e ora",
          //             "Durata",
          //             "Stato chiamata"
          //         ],
          //         calls: [
          //             {
          //                 image_filename: "lost.svg",
          //                 caller: "559",
          //                 datetime: "2022-08-11 10:38:20",
          //                 duration: 23,
          //                 call_status: "Conclusa",
          //                 row_text_color: "#000000"
          //             },
          //             {
          //                 image_filename: "answered.svg",
          //                 caller: "559",
          //                 datetime: "2022-08-11 10:36:03",
          //                 duration: 38,
          //                 call_status: "Non risposta",
          //                 row_text_color: "#FF0000"
          //             }
          //         ]
          //     }
          // }
            //-------------------------------

            //Populate headers
            var respHeaders = response.data.headers;
            var itemsHead: any = [];
            respHeaders.forEach((element: string, index: number) => {
              var head = {
                content: element,
                key: "header" + (index + 1).toString(),
                styles: { fontWeight: "bold" }
              };
              itemsHead.push(head);
            });
            const headerResult = {
              items: itemsHead
            };
            //Populate Rows
            var respRows = response.data.calls;
            var itemsRows: any = [];
            var imgRowName: any = "";
            respRows.forEach((element: any, index: number) => {
              var row: any = [];
              var textColor: string = "";
              Object.entries(element).forEach((value, idx) => {
                if(value[0] != 'row_text_color'){
                  var colElem = value[1] == null || value[1] == "" ? " " : value[1];
                  //handle duration display
                  if(value[0] && value[0] == 'duration' && value[1]){
                    var sec_num = parseInt(colElem.toString(), 10); 
                    var hours   = Math.floor(sec_num / 3600);
                    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                    var seconds = sec_num - (hours * 3600) - (minutes * 60);
                    var hourStr: string = hours.toString();
                    var minStr: string  = minutes.toString();
                    var secStr: string  = seconds.toString();
                    if (hours   < 10) {hourStr   = "0"+ hours;}
                    if (minutes < 10) {minStr = "0"+ minutes;}
                    if (seconds < 10) {secStr = "0"+ seconds;}
                    hourStr = hourStr + " h ";
                    minStr = minStr + " min ";
                    secStr = secStr + " sec ";
                    colElem = (hours <= 0 ? "" : hourStr) + (minutes <= 0 ? "" : minStr) + secStr;
                  }

                  if(value[0] && value[0] == 'caller' && value[1]){
                    colElem = 
                    <>
                      <div style={{ display: "flex", alignItems: "center", wordBreak: "break-all"}}>
                        <img
                          src={ process.env.PUBLIC_URL + "/" + imgRowName  }
                          className="callImage"
                          alt=""
                          // style={{
                          //   height: "35px",
                          //   width: "35px",
                          //   borderRadius: "3px",
                          //   marginRight: "30px"
                          // }}
                        ></img>
                        {value[1] as string}
                      </div>
                      
                    </>
                  }

                  if(value[0] && value[0] == 'image_filename' && value[1])
                    imgRowName = value[1];
                  else
                    row.push(colElem);

                } else {
                  if(typeof value[1] !== 'undefined' && value[1] != null)
                    textColor = value[1].toString();
                }
              });

              function changeTextColor(e : any) {
                e.target.style.color = (textColor ? textColor : "");
              }

              const objRow = {
                key: "row" + index,
                items: row,
                styles: { color: (textColor ? textColor : "")},
                onMouseOver : changeTextColor,
                onMouseOut: changeTextColor
                
              }
              itemsRows.push(objRow);
            });
            const rowsResult = itemsRows;
            setHeaders(headerResult);
            setRows(rowsResult);
          }

          //check if need polling
          if (Object.hasOwn(response.data, 'polling_interval')) {
            var polling = response.data.polling_interval;
            if(polling && polling != "0" && polling != 0){
              var sec: number = +polling;
              setTimeout(getCalls, sec * 1000);
            }
          }
        })
        .catch(function (error: any) {
          console.log("-----------ERROR - GET CALLS-------------");
          console.log(error);
        });
    }
    
    useEffect(() => {
      getCalls();
    }, []);

    return (
        <div className="ms-Grid" dir="ltr">
            <HeaderTab></HeaderTab>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12 marginTabElements">
                    <div className="ms-Grid-col ms-md4 ms-lg4 ms-xs4">
                    <h1>Lista chiamate</h1>
                    </div>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12 marginTabElements callTable" >
                    <Table
                        header={headers}
                        rows={rows}
                        aria-label="Nested navigation"
                    />
                </div>
            </div>
        </div>
    );
};
export default CallList;