import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIState } from "../interfaces/State";

const initialState: UIState = {};

interface OpenAlertAction {
  content: UIState["alertContent"];
  type: UIState["alertType"];
  keepOpen?: boolean;
}

export const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openAlert: (state, action: PayloadAction<OpenAlertAction>) => {
      state.alertContent = action.payload.content;
      state.alertType = action.payload.type;
      state.alertKeepOpen = action.payload.keepOpen;
    },
    closeAlert: (state) => {
      state.alertContent = undefined;
      state.alertType = undefined;
      state.alertKeepOpen = undefined;
    },
  },
});

export const { openAlert, closeAlert } = UISlice.actions;

export default UISlice.reducer;
